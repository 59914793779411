import { Controller } from '@hotwired/stimulus';
import { slideToggle } from '../helpers';
import Axios from '../axios';

export default class extends Controller {
	static get targets () {
		return ['couponToggle', 'couponSummary', 'couponSubmit', 'couponCode', 'couponDiscount', 'couponTotalDiscount', 'couponTotalPrice', 'agreementGroup'];
	}

	connect() {
		this._lang = document.documentElement.lang.split('-')[0];
	}

	couponToggleTargetConnected () {
		this.couponToggleTarget.addEventListener('click', () => {
			this.toggleCouponCode();
		});
	}

	couponSubmitTargetConnected () {
		this.couponSubmitTarget.addEventListener('click', () => {
			this.submitCouponCode();
		});
	}

	couponCodeTargetConnected () {
		this.couponCodeTarget.addEventListener('keypress', (e) => {
			if (e.keyCode === 13) {
				e.preventDefault();
				this.submitCouponCode();
				return false;
			}
		});
	}

	agreementGroupTargetConnected () {
		const checkboxes = this.agreementGroupTarget.querySelectorAll('input[type="checkbox"]');
		checkboxes.forEach(checkbox => {
			checkbox.addEventListener('change', () => {
				if (checkbox.classList.contains('single')) {
					checkboxes.forEach(cb => {
						if (cb !== checkbox) {
							cb.checked = false;
						}
					});
				} else {
					checkboxes.forEach(cb => {
						if (cb.classList.contains('single')) {
							cb.checked = false;
						}
					});
				}
			});
		});
	}
	
	toggleCouponCode() {
		slideToggle(this.couponSummaryTarget);
		this.couponToggleTarget.classList.toggle('active');

		if (this.couponToggleTarget.classList.contains('active')) {
			this.couponToggleTarget.querySelector('span').innerText = this.couponToggleTarget.dataset.hideText;
		} else {
			this.couponToggleTarget.querySelector('span').innerText = this.couponToggleTarget.dataset.showText;
		}
	}

	async submitCouponCode() {
		const element = this.couponSubmitTarget;
		const couponCodeInput = this.couponCodeTarget;
		const couponCode = couponCodeInput.value;
		const url = element.dataset.route + '?rest_language=' + this._lang;

		couponCodeInput.classList.remove('invalid');
		couponCodeInput.classList.remove('valid');
		couponCodeInput.parentNode.querySelectorAll('.input-error').forEach(error => {
			error.remove();
		});
		this.couponSummaryTarget.querySelectorAll('.general-success').forEach(error => {
			error.remove();
		});
		element.classList.add('loading');
		let response;

		await Axios.post(url, {
				code: couponCode
		})
				.then(res => {
					response = res.data.data;
				})
				.catch(error => {
					response = error.response.data.data;
				});

		element.classList.remove('loading');

		if (response && response.errors) {
			couponCodeInput.classList.add('invalid');
			const messageNode = document.createElement('span');
			messageNode.classList.add('input-error');
			messageNode.innerHTML = response.errors.code;
			couponCodeInput.parentNode.appendChild(messageNode);
			this.couponDiscountTarget.style.display = 'none';
			document.querySelector('form button[type=submit]').innerHTML = response.payNow;
		} else if (response && response.general_success) {
			const messageNode = document.createElement('div');
			messageNode.classList.add('general-success');
			messageNode.innerHTML = response.general_success;
			this.couponSummaryTarget.appendChild(messageNode);
			couponCodeInput.classList.add('valid');
		} else if (response && response.html) {
			document.querySelector('#page').insertAdjacentHTML('beforeend', response.html);
		} else {
			couponCodeInput.classList.add('valid');
			this.couponDiscountTarget.style.display = 'block';
			this.couponTotalDiscountTarget.innerHTML = response.totalDiscount;
			this.couponTotalPriceTarget.innerHTML = response.totalPrice;
			document.querySelector('form button[type=submit]').innerHTML = response.payNow;
		}
	}

	async selectClub(event) {
		const element = event.currentTarget;
		const clubId = element.dataset.clubId;
		const url = element.dataset.route + '?rest_language=' + this._lang;

		let response;
		element.classList.add('loading');
		await Axios.post(url, {
			id: clubId
		})
				.then(res => {
					response = res.data.data;
				})
				.catch(error => {
					response = error.response.data.data;
				});

		if (response && response.redirect) {
			window.location.href = response.redirect;
		} else {
			element.classList.remove('loading');
		}
	}

	async selectPlan(event) {
		const element = event.currentTarget;
		const planId = element.dataset.planId;
		const url = element.dataset.route + '?rest_language=' + this._lang;

		let response;
		element.classList.add('loading');
		await Axios.post(url, {
			id: planId
		})
				.then(res => {
					response = res.data.data;
				})
				.catch(error => {
					response = error.response.data.data;
				});

		if (response && response.redirect) {
			window.location.href = response.redirect;
		} else {
			element.classList.remove('loading');
		}
	}

	async selectPlanAndClub(event) {
		const element = event.currentTarget;
		const planId = element.dataset.planId;
		const clubId = element.dataset.clubId;
		const url = element.dataset.route + '?rest_language=' + this._lang;

		let response;
		element.classList.add('loading');
		await Axios.post(url, {
			plan_id: planId,
			club_id: clubId
		})
				.then(res => {
					response = res.data.data;
				})
				.catch(error => {
					response = error.response.data.data;
				});

		if (response && response.redirect) {
			window.location.href = response.redirect;
		} else {
			element.classList.remove('loading');
		}
	}

	back(event) {
		const element = event.currentTarget;

		if (element.dataset.back && element.dataset.current) {
			const backSelector = document.querySelector(`.${element.dataset.back}`);
			const currentSelector = document.querySelector(`.${element.dataset.current}`);

			backSelector.classList.add('d-block');
			backSelector.classList.remove('d-none');

			currentSelector.classList.remove('d-block');
			currentSelector.classList.add('d-none');
		}
	}

	async loadPaymentMethods(event) {
		const element = event.currentTarget;
		const paymentMethodsEl = document.querySelector('#paymentMethods');

		if (paymentMethodsEl) {
			paymentMethodsEl.classList.add('loading-block');
			element.tomselect.disable();

			let response;
			element.classList.add('loading');
			await Axios.get(`${element.dataset.route}?lang=${element.value}`)
				.then(res => {
					response = res.data.data;
				})
				.catch(error => {
					response = error.response.data.data;
				});

			if (response.success) {
				paymentMethodsEl.innerHTML = response.template;
			}

			paymentMethodsEl.classList.remove('loading-block');
			element.tomselect.enable();
		}
	}

	async addAddon(event) {
		const element = event.currentTarget;
		const addonId = element.dataset.addonId;
		const url = element.dataset.route + '?rest_language=' + this._lang;

		let response;
		element.classList.add('loading');
		await Axios.post(url, {
			id: addonId
		})
			.then(res => {
				response = res.data.data;
			})
			.catch(error => {
				response = error.response.data.data;
			});

		if (response.success) {
			let nextElement = element.parentElement.nextElementSibling;
			element.parentElement.classList.toggle('d-none');
			nextElement.classList.toggle('d-none');
		}

		element.classList.remove('loading');
	}

	async removeAddon(event) {
		const element = event.currentTarget;
		const addonId = element.dataset.addonId;
		const url = element.dataset.route + '?rest_language=' + this._lang;

		let response;
		element.classList.add('loading');
		await Axios.post(url, {
			id: addonId
		})
			.then(res => {
				response = res.data.data;
			})
			.catch(error => {
				response = error.response.data.data;
			});

		if (response.success) {
			let prevElement = element.parentElement.previousElementSibling;
			element.parentElement.classList.toggle('d-none');
			prevElement.classList.toggle('d-none');
		}

		element.classList.remove('loading');
	}

	async resendOtp(event) {
		const element = event.currentTarget;
		const url = element.dataset.route + '?rest_language=' + this._lang;
		const action = element.dataset.otpAction;
		const phoneField = document.querySelector(element.dataset.input);
		const phone = phoneField.value;

		let response;
		element.classList.add('loading');
		phoneField.classList.remove('invalid');
		phoneField.parentNode.querySelectorAll('.input-error').forEach(error => {
			error.remove();
		});

		element.parentNode.querySelectorAll('.general-success, .general-error').forEach(error => {
			error.remove();
		});

		let recaptcha = '';
		if (window.grecaptcha) {
			await new Promise(resolve => {
				window.grecaptcha.ready(() => {
					window.grecaptcha.execute(document.querySelector('meta[name="global_recaptcha_site_key"]').content, { action: 'submit' })
						.then(recaptchaToken => {
							recaptcha = recaptchaToken;
							resolve();
						});
				});
			});
		}

		await Axios.post(url, {
			action: action,
			phone: phone,
			recaptcha: recaptcha
		})
			.then(res => {
				response = res.data.data;
			})
			.catch(error => {
				response = error.response.data.data;

				if (response.errors.phone) {
					phoneField.classList.add('invalid');
					const messageNode = document.createElement('span');
					messageNode.classList.add('input-error');
					messageNode.innerHTML = response.errors.phone;
					phoneField.parentNode.appendChild(messageNode);
				}
			});

		element.classList.remove('loading');
		if (response.general_success) {
			let retryText = element.dataset.retryText;

			if (retryText) {
				retryText = retryText.replace('%s', ' <span class="resend-otp-timer ms-4px">15</span>');
				element.innerHTML = retryText;
				element.disabled = true;

				const counter = setInterval(() => {
					const timer = element.querySelector('.resend-otp-timer');
					let time = parseInt(timer.innerHTML);
					time--;
					timer.innerText = time;
				}, 1000);

				setTimeout(() => {
					clearInterval(counter);
					element.innerHTML = element.dataset.retryTextFinished;
					element.disabled = false;
				}, 15000);

			}

			const messageNode = document.createElement('p');
			messageNode.classList.add('mb-0');
			messageNode.innerHTML = response.general_success;
			messageNode.classList.add('general-success');
			retryText ? element.parentNode.appendChild(messageNode) : element.replaceWith(messageNode);
		} else if (response.general_error) {
			const messageNode = document.createElement('p');
			messageNode.classList.add('mb-0');
			messageNode.innerHTML = response.general_error;
			messageNode.classList.add('general-error');
			element.parentNode.appendChild(messageNode);
		}

		if (response.hide_retry) {
			element.remove();
		}
	}
}
