import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr';
import { english } from 'flatpickr/dist/l10n/default.js';
import { Latvian } from 'flatpickr/dist/l10n/lv.js';
import { Russian } from 'flatpickr/dist/l10n/ru.js';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';

export default class extends Controller {
    connect() {
        const minDate = this.element.dataset.minDate || 'today';
        const maxDate = this.element.dataset.maxDate || null;

        let options = {
            minDate: minDate,
            maxDate: maxDate,
            locale: this.getLocale(),
            dateFormat: 'Y-m-d',
            disableMobile: true,
        };

        if (this.element.dataset.monthsOnly) {
            options.plugins = [
                new monthSelectPlugin({ dateFormat: 'Y-m-d', shorthand: false, altFormat: 'F Y' }),
            ];
            options.altInput = true;
        }

        if (this.element.dataset.disableWeekends) {
            options.disable = [
                function (date) {
                    return (date.getDay() === 0 || date.getDay() === 6);
                }
            ];
        }

        if (this.element.dataset.setSelect) {
            const target = document.querySelector(`#${this.element.dataset.setSelectId}`);

            if (target) {
                this.element.addEventListener('change', () => {
                    target.innerHTML = '';
                    target.tomselect.clear();
                    target.tomselect.clearOptions(() => {
                        return false;
                    });

                    const d = new Date(this.element.value);
                    let day = d.getDay();

                    let newOptions = this.element.dataset.setSelect;
                    newOptions = newOptions ? JSON.parse(newOptions) : null;


                    if (newOptions !== null) {
                        newOptions = newOptions.filter(x => x.day == day);

                        if (newOptions.length > 0) {
                            let html = '';
                            newOptions.forEach(opt => {
                                let times = [];
                                opt.times.forEach(time => {
                                    times.push({
                                        value: time,
                                        text: time
                                    });
                                });

                                html += `<option value="${opt.value}" data-options='${JSON.stringify(times)}'>${opt.text}</option>`;
                            });

                            target.innerHTML = html;
                            target.tomselect.sync();
                            target.tomselect.trigger('change');
                        }
                    }
                });
            }
        }

        flatpickr(this.element, options);
    }

    getLocale() {
        switch (document.documentElement.lang.split('-')[0]) {
            case 'lv':
                return Latvian;
            case 'en':
                return english;
            case 'ru':
                return Russian;
            default:
                return Lithuanian;
        }
    }
}
